import React from 'react'
import {withPrefix} from 'gatsby'
import Helmet from 'react-helmet'
import favicon from '../../resources/favicon.png'
import '../../resources/source/stylesheets/pension-times.scss'
import {metadata} from '../../../config.js';
import loadable from "@loadable/component";
import {isMobile} from "react-device-detect";
import Header from "../header";
import {delayMultiplicationFactor, googleScriptLoadingDelay, isBrowser} from "../../utils/articleUtil";
import consts from "../../constants/consts";

const Footer = loadable(() => import("../footer"));
const AdBottomSticky = loadable(() => import("../ads/adBottomSticky"));

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryPath: this.categoryPath,
            subCategoryPath: this.subCategoryPath,
            timeouts: [],
            timeoutMultiplier: 1
        }
    }

    componentDidMount() {
        const headElement = document.getElementsByTagName("head")[0];

        const _this = this;
        const timeoutMultiplier = delayMultiplicationFactor(isMobile);
        this.setState({timeoutMultiplier});

        /*this.state.timeouts.push(setTimeout(() => {
            if (!document.querySelector('script[src*="otSDKStub.js"]')) {
                const consentScript = document.createElement("script");
                consentScript.async = true;
                consentScript.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
                consentScript.type = "text/javascript";
                consentScript.setAttribute('data-domain-script', "db57f6d6-302a-4db6-9f29-1f0b4bf8b1e1");
                headElement.appendChild(consentScript);
            }
        }, consts.SCRIPT_LOADING_TIME_WAIT * (2 + timeoutMultiplier)));*/

        this.state.timeouts.push(setTimeout(() => {
            this.addAccessibilityScript();
        }, consts.SCRIPT_LOADING_TIME_WAIT * (4.5 + timeoutMultiplier)));

        this.state.timeouts.push(setTimeout(() => {
            if (!document.querySelector('script[src*="osd.js"]')) {
                const googleActiveViewScript = document.createElement("script");
                googleActiveViewScript.async = true;
                googleActiveViewScript.src = `https://www.googletagservices.com/activeview/js/current/osd.js?cb=%2Fr20100101`;
                headElement.appendChild(googleActiveViewScript);
            }

            if (!document.querySelector('script[src*="gtm"]')) {
                const gtmScript = document.createElement("script");
                gtmScript.src = `${withPrefix(`gtm${_this.isProduction() ? '' : '-test'}.js`)}`;
                gtmScript.async = true;
                headElement.appendChild(gtmScript);
            }

            if (!document.querySelector('script[src*="gpt.js"]')) {
                _this.addGPTScript();
            } else if (!document.querySelector('script[src*="ad.tallmanads_2.0.3.js"]')) {
                _this.addAdScript();
            } else {
                _this.refreshAd();
            }

            _this.addGptPassBack();
        }, googleScriptLoadingDelay() * timeoutMultiplier));
    }

    addGptPassBack() {
        window.googletag = window.googletag || {cmd: []};
        const params = new URLSearchParams(window.location.search);
        googletag.cmd.push(function() {
            try {
                googletag.defineSlot('/430837318/TOTAL_TAS/PENSION_TIMES_LTD', [1, 1], 'gpt-passback')
                    .addService(googletag.pubads())
                    .setTargeting('tmPtS', [params.get('utm_source')])
                    .setTargeting('tmPtM', [params.get('utm_medium')])
                    .setTargeting('tmDmn', ['pensiontimes.co.uk'])
                    .setTargeting('tmClnt', ['PENSION TIMES LTD']);
                googletag.enableServices();
                googletag.display('gpt-passback');
            } catch (e) {
                console.log(e);
            }
        });
    }

    addGPTScript() {
        console.log("==========addGPTScript gpt loading start", Date.now());
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        adScript.async = true;
        headElement.appendChild(adScript);
        adScript.onload = () => {
            this.state.timeouts.push(setTimeout(() => {
                /*if (!document.querySelector('script[src*="biddr.brealtime.com"]')) {
                    const bidderScript = document.createElement("script");
                    bidderScript.src = 'https://biddr.brealtime.com/14888734-1688.js';
                    headElement.appendChild(bidderScript);
                }*/
                _this.addAdScript();
            }, this.state.timeoutMultiplier));
        }
    }

    addAdScript() {
        console.log("==========addAdScript ad.min", Date.now());
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `${withPrefix('ad.tallmanads_2.0.3.js')}`;
        adScript.type = "text/javascript";
        headElement.appendChild(adScript);
        adScript.onload = () => {
            this.state.timeouts.push(setTimeout(() => {
                _this.refreshAd();
            }, 500 * this.state.timeoutMultiplier));
        }
    }

    refreshAd() {
        this.addGptPassBack();

        this.state.timeouts.push(setTimeout(() => {
            try {
                window.googletag.pubads().refresh(null, {changeCorrelator: true});
            } catch (e) {
                console.log('googletag: ' + e);
            }
        }, 100));
    }

    addAccessibilityScript() {
        const accessibilityOptions = {
            animations: {buttons: false},
            modules: {
                increaseText: true,
                decreaseText: true,
                invertColors: [true / false],
                increaseTextSpacing: false,
                decreaseTextSpacing: false,
                grayHues: true,
                underlineLinks: false,
                bigCursor: true,
                readingGuide: true,
                textToSpeech: true,
                speechToText: true
            },
            icon: {
                useEmojis: true
            }
        }
        const headElement = document.getElementsByTagName("head")[0];
        const _this = this;
        if (!document.querySelector('script[src*="accessibility.min.js"]')) {
            const script = document.createElement("script");
            script.src = `${withPrefix('accessibility.min.js')}`;
            script.type = "text/javascript";
            headElement.appendChild(script);
            script.onload = () => {
                _this.state.timeouts.push(setTimeout(() => {
                    new Accessibility(accessibilityOptions);
                }, 1000));
            }
        }
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    categoryPath = () => {
        return isBrowser() && window.location.pathname.split('/')[1];
    }

    subCategoryPath = () => {
        if (isBrowser()) {
            const pathArray = window.location.pathname.split('/');
            if (pathArray && pathArray[2]) {
                return pathArray[2];
            }
        }
        return false;
    }

    isProduction = () => {
        return isBrowser() && ['www.pensiontimes.co.uk', 'pensiontimes.co.uk'].includes(window.location.host);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate}>
                    {this.props.preloadImage && <link rel="preload" as="image" href={this.props.preloadImage.src}
                        imagesrcset={this.props.preloadImage.webpSrcSet} imagesizes={this.props.preloadImage.sizes}/>}
                    <meta name="author" content={metadata.author}/>
                    <meta name="description" content={metadata.description}/>
                    <html lang="en"/>
                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta httpEquiv="Content-Language" content="en"/>
                    <meta property={metadata.facebookId} content="Pension-Times-103096144798202"/>
                    <meta property={metadata.twitterId} content="PensionTimes"/>
                    <script src={withPrefix('google-funding-choices.js')} type="text/javascript" />
                    <link title="timeline-styles" rel="shortcut icon" type="image/png" href={favicon}/>
                    <noscript>
                        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${this.isProduction() ? '5NJXQFW' : 'KWLQ3NN'}"
                                height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                    </noscript>
                    <link as="style" rel="stylesheet preload prefetch" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Prata&display=swap"/>
                </Helmet>
                <div id="outer-container">
                    <Header path={this.props.path} categoryPath={this.state.categoryPath()} subCategoryPath={this.state.subCategoryPath()}/>
                    {this.props.children}
                    <AdBottomSticky/>
                    <Footer categoryPath={this.state.categoryPath()} subCategoryPath={this.state.subCategoryPath()}/>
                    <div id="gpt-passback"/>
                </div>
            </React.Fragment>
        )
    }
}

export default Layout
