import logoWhite from "../../../static/images/logos/logo-white.png";
import React, {useState} from "react";
import {URL} from "../../constants/urls";
import {FacebookIcon, TwitterIcon} from "../../icons";
import SubscribePopup from "../subscribe/SubscribePopup";
import {formatDate} from "../../utils/articleUtil";
import Navigate from "../navigate";

function FooterHeader(props) {
    const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
    return <>
        <div className={`top-content-header ${props.customClass ? props.customClass : ''}`} data-datocms-noindex>
            <div className="logo-section">
                <Navigate href="/" className={'logo-anchor'}>
                    <img src={logoWhite} alt="logo"/>
                </Navigate>
                <div className="date-container">
                    <time className={'date'}>{formatDate()}</time>
                </div>
                <div className="social-media-container">
                    <a href={URL.FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer">
                        <FacebookIcon size={32} round={true}/>
                    </a>
                    <a href={URL.TWITTER_HANDLE} target="_blank" rel="noopener noreferrer">
                        <TwitterIcon size={32} round={true}/>
                    </a>
                    <a href={URL.INSTAGRAM_PAGE} target="_blank" rel="noopener noreferrer" className={'instagram'}>
                        <div style={{width: '32px', height: '32px'}}>
                            <svg viewBox="0 0 500 500" width={32} height={32} xmlns="http://www.w3.org/2000/svg">
                                <path d="M250,193.27A56.73,56.73,0,1,0,306.73,250,56.8,56.8,0,0,0,250,193.27Z"/>
                                <path d="M316.74,105.49H183.26a77.86,77.86,0,0,0-77.77,77.77V316.74a77.86,77.86,0,0,0,77.77,77.77H316.74a77.86,77.86,0,0,0,77.77-77.77V183.26A77.86,77.86,0,0,0,316.74,105.49ZM250,336.7A86.7,86.7,0,1,1,336.7,250,86.8,86.8,0,0,1,250,336.7Zm95.27-160.26A21.41,21.41,0,1,1,366.68,155,21.41,21.41,0,0,1,345.27,176.45Z"/>
                                <path d="M484.85,124.74a144.17,144.17,0,0,0-2.32-25.29c-1.94-10.19-4.67-20.12-9.55-29.33A101.84,101.84,0,0,0,453.39,44a97.14,97.14,0,0,0-42.76-24.4c-14.83-4-30-4.84-45.21-4.82a.46.46,0,0,1-.09-.23H134.59c0,.08,0,.16,0,.23-8.65.16-17.32.09-25.92,1.16A123.46,123.46,0,0,0,81,22.14,97.48,97.48,0,0,0,44.25,46.26,97.15,97.15,0,0,0,19.68,89.17c-3.94,14.72-4.8,29.73-4.82,44.85L14.7,365.69v0c.28,10.45.37,21,2.13,31.36,1.87,11,4.54,21.71,9.64,31.69A101.36,101.36,0,0,0,54.77,463a91.91,91.91,0,0,0,28.31,15.35c15.12,4.88,30.72,6.75,46.55,6.84,9.88.06,19.74.31,29.62.27,71.74-.3,143.49.52,215.23-.44a169.32,169.32,0,0,0,28.23-3A95.61,95.61,0,0,0,450,459c15.78-14.08,26.43-31.3,31.24-52.09,3.15-13.59,3.93-27.38,4.07-41.21v-2.76C485.3,361.86,484.89,127.84,484.85,124.74Zm-60.38,192A107.87,107.87,0,0,1,316.74,424.48H183.26A107.87,107.87,0,0,1,75.52,316.74V183.26A107.87,107.87,0,0,1,183.26,75.52H316.74A107.87,107.87,0,0,1,424.48,183.26Z"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className="subscribe-section" onClick={() => {
                setIsSubscribeOpen(true)
            }}>
                <div className={`subscribe ${props.subscribeClass ? props.subscribeClass : ''}`}>
                    <a dangerouslySetInnerHTML={{__html: props.subscribeText}}>
                    </a>
                </div>
            </div>
        </div>
        {isSubscribeOpen && <SubscribePopup onPopupClose={() => {
            setIsSubscribeOpen(false)
        }}/>}
    </>
}

export default FooterHeader;