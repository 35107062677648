import React, {useState} from "react";
import Popup from "../ui/popup/Popup";
import {validateEmail} from "../../utils/articleUtil";

function SubscribePopup(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [tnc, setTnC] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);

    const onClosePopup = () => {
        props.onPopupClose();
    }

    const onActionClick = async (action) => {
        switch (action) {
            case 'no':
                onClosePopup();
                break;
            case 'yes':
                if(validateForm()) {
                    setLoading(true);
                    fetch(`/api/subscribe?name=${name}&email=${email}&source=${window.location.pathname}&marketing=${marketing}`)
                        .then(resp => resp.json())
                        .then(res => {
                            setLoading(false);
                            alert('Successfully subscribed to our newsletter');
                            onClosePopup();
                        });
                }
                break;
        }
    }

    const validateForm = () => {
        if (!name || name.trim() === '') {
            setError('Please enter valid name');
            return false;
        }
        if (!email || !validateEmail(email)) {
            setError('Please enter valid email');
            return false;
        }
        if (!tnc) {
            setError('Please accept Pension Times’ Terms and Privacy Policy');
            return false;
        }
        return true;
    }

    return (
        <Popup onClose={onClosePopup} customClass={'subscribe-popup'} data-datocms-noindex>
            <div className={'heading'}>
                Stay on Top of the News!
            </div>
            <div className={'subscribe-message'}>
                Subscribe to our newsletter and receive the latest articles directly in you mailbox
            </div>
            <div className={'heading'}>
                Subscribe Now!
            </div>
            {error && <div className={'error-message'}>{error}</div>}
            <input type="text" placeholder="Enter your first name*" value={name} onChange={(event) => {
                setError(null);
                setName(event.target.value);
            }}/>
            <input type="email" placeholder="Enter your email*" value={email} onChange={(event) => {setError(null);setEmail(event.target.value)}}/>
            <div className={'checkbox-container'}>
                <input type="checkbox" id="tnc" defaultChecked={tnc} onChange={() => setTnC(!tnc)}/>
                <label htmlFor="tnc">
                    I accept and acknowledge Pension Times’ <a href="/website-terms-of-use" target="_blank">
                    Terms</a> and <a href="/website-privacy-policy" target="_blank">Privacy Policy</a>
                </label>
            </div>
            <div className={'checkbox-container'}>
                <input type="checkbox" id="marketing" defaultChecked={marketing} onChange={() => setMarketing(!marketing)}/>
                <label htmlFor="marketing">
                    I’m happy for Pension Times to send me selected marketing information which may be of interest to me.
                </label>
            </div>
            <div className={'action-container'}>
                <button onClick={() => {onActionClick('no');}} className={'subscribe-button-no'}>No, Thanks</button>
                <button onClick={() => {onActionClick('yes');}} className={'subscribe-button-yes ok'} disabled={loading}>
                    I’m Ready
                    {loading && (
                        <img
                            src="/images/loading.svg"
                            style={{ marginLeft: "5px" }}
                        />
                    )}
                </button>
            </div>
        </Popup>
    );
}

export default SubscribePopup