import React, {useEffect, useRef, useState} from 'react'
import {graphql, navigate, StaticQuery, withPrefix} from 'gatsby'
import FooterHeader from "../footer/footerHeader";
import {STRINGS} from "../../constants/strings";
import HamburgerMenu from "./menu";
import AdHeader from "../ads/adHeader";
import {isMobile} from "react-device-detect";
import {formatDate} from "../../utils/articleUtil";
import Navigate from "../navigate";

const Header = props => {
    const catRefs = [];
    const hasWindow = typeof window !== 'undefined';
    const [width, setWidth] = useState(null);
    const [enableSearch, setEnableSearch] = useState(true);
    const [subscribeText, setSubscribeText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isSearchOpened, setIsSearchOpened] = useState(false);

    useEffect(() => {
        setWidth(hasWindow ? window.innerWidth : null);
    }, [hasWindow]);

    useEffect(() => {
        setSubscribeText(isMobile ? STRINGS.SUBSCRIBE_NOW : STRINGS.SUBSCRIBE);
    }, [isMobile]);

    if (typeof window !== "undefined") {
        useEffect(() => {
            if (window.location.pathname.startsWith('/search')) {
                setEnableSearch(false);
            }
        }, [window.location.pathname]);
    }

    const getClassName = (category) => {
        let className = '';
        if (props.categoryPath && props.categoryPath === category.slug) {
            className = 'active';
        }
        if (props.subCategoryPath && props.subCategoryPath === category.slug) {
            className = 'active';
        }
        return className;
    }

    const updateRefClass = (index) => {
        if (catRefs[index].current.className.includes('expanded')) {
            catRefs[index].current.className = catRefs[index].current.className.replace(' expanded', '');
        } else {
            // remove expanded from all other categories if any
            catRefs.forEach((ref) => {
                if (ref.current && ref.current.className) {
                    ref.current.className = ref.current.className.replace(' expanded', '');
                }
            });
            catRefs[index].current.className += ' expanded';
        }
    }

    const onIconClick = (event, index) => {
        updateRefClass(index);
    }

    const renderNavItems = () => (
        <StaticQuery
            query={graphql`
          query HeaderCategoryQuery {
              datoCMS {
                allCategories(filter: {parent: {exists: "false"}}, orderBy: position_ASC) {
                  slug
                  title
                  children {
                    slug
                    title
                  }
                }
              }
            }
        `}
            render={data =>
                data.datoCMS.allCategories.map((category, index) => {
                    const catRef = useRef();
                    catRefs[index] = catRef;
                    if (category.slug === 'news') {
                        return null;
                    }
                    return <div className={`main-nav__item dropdown ${category.slug}`} key={category.slug} ref={catRef} data-datocms-noindex>
                        <div className={"category-link-container"}>
                            <Navigate href={`/${category.slug}`} className={getClassName(category)}>
                                {category.title}
                            </Navigate>
                            <div className={"category-down-icon"}
                                 onClick={(event) => {
                                     onIconClick(event, index)
                                 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown-content">
                            <div className="content-row">
                                {category.children.map((subCategory) =>
                                    <Navigate className={`sub-nav__item ${subCategory.slug === props.subCategoryPath ? 'active' : ''}`} key={subCategory.slug}
                                          href={`/${category.slug}/${subCategory.slug}`}>
                                        {subCategory.title}
                                    </Navigate>
                                )}
                            </div>
                        </div>
                    </div>
                })
            }
        />
    )

    setTimeout(() => {
        catRefs.forEach((ref) => {
            if (!ref.current) {
                return;
            }
            if (ref.current && ref.current.className && ref.current.className.split(' ').includes(props.categoryPath)) {
                ref.current.className += ' active';
            } else {
                ref.current.className = ref.current.className.replace(' active', '');
            }
        });
    }, 0);

    const gteLogoPath = () => {
        let logoPath = '/images/logos/';
        const path = props.path?.split('/');
        if (path && path.length) {
            logoPath += `logo-${path[1].replace('-category', '')}.png`
        } else {
            logoPath += 'logo.png';
        }

        return withPrefix(logoPath);
    }

    const onSearchTextChange = (event) => {
        setSearchText(event.target.value)
    }

    const closeSearch = () => {
        setIsSearchOpened(false);
    }

    const openSearch = () => {
        setIsSearchOpened(true);
    }

    const goToSearch = () => {
        setIsSearchOpened(false);
        navigate(`/search/${searchText}`);
        setSearchText('');
    }

    return (
        <>
            {width <= 780 && <HamburgerMenu classname="hamburger-menu">
                <div className="main-nav__item" key="home">
                    <Navigate href="/" className={props.categoryPath === '' ? 'active' : ''}>{STRINGS.HOME}</Navigate>
                </div>
                <div className="main-nav__item" key="latest">
                    <Navigate href="/news-category" className={props.categoryPath === 'news-category' ? 'active' : ''}>{STRINGS.LATEST_NEWS}</Navigate>
                </div>
                {renderNavItems()}
            </HamburgerMenu>}
            <FooterHeader
                customClass="header"
                subscribeText={subscribeText}
                subscribeClass="solid-background"/>
            <div className="header-container" data-datocms-noindex>
                <header className="page-head" role="banner" style={{marginBottom: '0px'}}>
                    {(!width || width > 780) && <div className={"logo-date-container"}>
                        <div className="logo">
                            <Navigate href="/">
                                <img src={gteLogoPath()} height="70" alt="logo"/>
                            </Navigate>
                        </div>
                        <div className="date-container">
                            <time className={'date'}>{formatDate()}</time>
                            {enableSearch && <div className={'search'} onClick={openSearch}>Search <img width="25" height="25" src={'/images/search.png'}/></div>}
                            {isSearchOpened && <div className="search-container">
                                <div className={'input-container'}>
                                    <input type={'text'} value={searchText} onChange={onSearchTextChange}/>
                                    <button onClick={goToSearch}>SEARCH</button>
                                    <div className={'close-button'} onClick={closeSearch}>x</div>
                                </div>
                            </div>}
                        </div>
                    </div>}
                    <AdHeader/>
                    {(!width || width > 780) && <div className="nav-container">
                        <nav className="main-nav" role="navigation">
                            <div className="main-nav__item" key="home">
                                <Navigate href="/" className={props.categoryPath === '' ? 'active' : ''}>{STRINGS.HOME}</Navigate>
                            </div>
                            <div className="main-nav__item" key="latest">
                                <Navigate href="/news-category" className={props.categoryPath === 'news-category' ? 'active' : ''}>{STRINGS.LATEST_NEWS}</Navigate>
                            </div>
                            {renderNavItems()}
                        </nav>
                    </div>}
                </header>
            </div>
        </>
    )
}

export default Header
