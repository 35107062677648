import React from "react";

import Menu from 'react-burger-menu/lib/menus/slide'

const HamburgerMenu = (props) => {
    return <div className={`${props.classname} left`} data-datocms-noindex>
        <Menu
            id="slide"
            disableAutoFocus
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}>
            {props.children}
        </Menu>
    </div>
}

export default HamburgerMenu;

